<template>
    <div class="add-re-">
        <div class="first" v-if="active==0" v-loading="loading">
            <div class="inputName">
                <el-input v-model="testName" placeholder="请输入考核名称，如：麦当劳报告考核"></el-input>
                <div class="btn-box">
                    <el-button @click="addReportTest" icon="el-icon-right" type="success">下一步</el-button>
                </div>
            </div>
        </div>
        <div class="second" v-if="active==1">
            <div class="list">
                <el-empty v-if="list.length<=0" description="请输入审核项目"></el-empty>
                <!-- listd -->
                <el-table
                :data="list"
                v-loading="loading"
                style="width: 100%">
                    <el-table-column
                    prop="name"
                    label="名称">
                    </el-table-column>
                    <el-table-column
                    prop="type"
                    label="类型">
                    <template slot-scope="scope">
                        <i class="el-icon-paperclip"></i>
                        <span style="margin-left: 10px">{{ scope.row.type|getType }}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="remark"
                    label="备注">
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="deleteRow(scope.$index,list)" type="text" size="small">删除</el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="form">
                <el-form-item label="项目名" required>
                    <el-input v-model="formInline.name" placeholder="请输入项目名"></el-input>
                </el-form-item>
                <el-form-item label="类型" required>
                    <el-select v-model="formInline.type" placeholder="选择类型">
                        <el-option label="数字记分" value="num"></el-option>
                        <el-option label="数据统计" value="text"></el-option>
                        <el-option label="日期" value="date"></el-option>
                        <el-option label="时间" value="time"></el-option>
                        <el-option label="客户名称" value="client"></el-option>
                        <el-option label="工程人员" value="staff"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="formInline.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-upload2" type="success" @click="addTestItem">添加</el-button>
                    <el-button icon="el-icon-check" :disabled="loading" type="danger" @click="save">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="step-3" v-else-if="active==3">
            <el-result icon="success" title="保存成功！" subTitle="点击返回关闭弹窗">
            <template slot="extra">
                <el-button type="primary" size="medium" @click="back">返回</el-button>
            </template>
            </el-result>
        </div>
        <!-- steps -->
        <el-steps :active="active" finish-status="success" align-center>
            <el-step title="添加考评名称" description="如；麦当劳报告考评"></el-step>
            <el-step title="添加审核项目" description="如：需要审核员工证件，则添加:名称为‘员工证件'，类型为'数字记分',备注:'有且在有效期+10分，无则扣10分'"></el-step>
            <el-step title="提交"></el-step>
        </el-steps>
    </div>
</template>
<script>
import vPinyin from '@/utils/py.js';
export default {
    name:"AddReportTest",
    props:{

    },
    data(){
        return{
            user:{},
            loading:false,
            tid:"",//链接考核名称
            testName:"",//考核名称
            active:0,
            list:[
                {
                    name:"日期",
                    remark:"作业日期",
                    type:"date"
                },{
                    name:"工程师",
                    remark:"工程师",
                    type:"staff"
                },{
                    name:"开始时间",
                    remark:"开始时间",
                    type:"time"
                },{
                    name:"结束时间",
                    remark:"结束时间",
                    type:"time"
                },{
                    name:"客户名称",
                    remark:"客户名称",
                    type:"client"
                }
            ],
            formInline:{
                name:"",
                remark:"",
                key:"",
                type:""
            }
        }
    },
    filters:{
        getType(type){
           let str = ""
            switch(type){
                case "date":
                    str = "日期"
                    break;
                case "time":
                    str = "时间"
                    break;
                case "staff":
                    str = "工程人员"
                    break;
                case "client":
                    str = "客户"
                    break;
                case "num":
                    str = "记分"
                    break;
                case "text":
                    str = "数据统计"
                    break;
            }
            return str;
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem("user"))
    },
    methods:{
        back(){
            this.active = 0
            this.tid = "" 
            this.testName = ""
            this.list=[
                {
                    name:"日期",
                    remark:"作业日期",
                    key:"date",
                    type:"date"
                },{
                    name:"客户名称",
                    remark:"客户名称",
                    key:"client",
                    type:"client"
                },{
                    name:"工程师",
                    remark:"工程师",
                    key:"staff",
                    type:"staff"
                },{
                    name:"开始时间",
                    remark:"开始时间",
                    key:"start",
                    type:"time"
                },{
                    name:"结束时间",
                    remark:"结束时间",
                    key:"end",
                    type:"time"
                }
            ]
            this.$emit("back")
        },
        deleteRow(index,list){
            console.log(index)
            list.splice(index,1)
            console.log(this.list)
        },
        addTestItem(){
            if(!this.formInline.name||!this.formInline.type){
                this.$message({
                    message:"请输入必填项目！",
                    type: 'warning'
                });
                return
            }
            this.formInline.key = vPinyin.chineseToPinYin(this.formInline.name)
            this.list.push(this.formInline);
            console.log(this.list)
            this.formInline={
                name:"",
                remark:"",
                key:"",
                type:""
            }
            this.$forceUpdate()
        },
        addReportTest(){
            if(!this.testName||this.testName.length<=2){
                this.$message({
                    message:!this.testName?"请输入考评名称！":"考评名称需要大于2个字符",
                    type: 'warning'
                });
                return
            }
             this.loading = true
             let params = {
                 method:"addReportTest",
                 name:this.testName,
                 author:this.user.username
             }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                console.log(data)
                if(data[0].id){
                    this.active = 1
                    this.tid = data[0].id
                    this.$emit("saveName",data[0].name)
                }
                
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        save(){
            let hasCal = false 
            this.list.forEach(item=>{
                if(!item.key){
                    item.key = vPinyin.chineseToPinYin(item.name)
                }
                if(item.type=="num"){
                    hasCal = true
                }
            })
            if(!hasCal){
                this.$message({
                    message: "还没有记分项目！",
                    type: 'warning'
                });
                return
            }
            if(!this.tid){
                return
            }
            let params = {
                method:"addReportTestItem",
                tid:this.tid,
                name:this.testName,
                list:JSON.stringify(this.list)
            }
            this.loading = true
            // console.log(params)
            // return
            this.$ajax.post(this.API.api,params).then(({data})=>{
                console.log(data)
                this.$message({
                    message: "保存成功1",
                    type: 'success'
                });
                this.active = 3
                this.loading = false
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
            
        }
    }
}
</script>
<style lang="less">
.add-re-{
    .inputName{
        max-width: 500px;
        margin:10px auto;
    }
    .list{
        border-bottom: 1px #dbdbdb solid;
        margin-bottom: 10px;
        overflow: scroll;
    }
    .el-table {
        ::v-deep .el-table__body-wrapper {
            overflow: hidden;
        }
        ::v-deep .el-table__fixed {
            height: 100% !important;
            width: 100% !important;
            overflow-x: scroll;
        }
        ::v-deep .el-table__fixed-right {
            z-index: 4;
        }
        }

}
</style>