<template>
    <div class="test-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>报告考评</el-breadcrumb-item>
            <el-breadcrumb-item>考评设置</el-breadcrumb-item>
        </el-breadcrumb>
         <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="drawer=true" round type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item>
                     <el-input size="normal" @input="search" placeholder="请输入关键字" v-model="form.keyword">
                        <!-- <template slot="prepend">关键词</template> -->
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list -->
        <div class="list-box">
            <el-table
                :data="list"
                style="width: 100%">
                    <el-table-column
                    prop="name"
                    label="名称">
                    </el-table-column>
                    <el-table-column
                    prop="author"
                    label="作者">
                    </el-table-column>
                    <el-table-column
                    prop="time"
                    label="提交时间">
                    </el-table-column>
                    <el-table-column
                    prop="count"
                    label="项目数量">
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="edClick(scope.row.id)" type="text" size="small">进入</el-button>
                        <el-button @click.native.prevent="deleteRow(scope.$index,list)" type="text" size="small">删除</el-button>
                    </template>
                    </el-table-column>
                </el-table>
        </div>
        <!-- page -->
        <div class="pageNav" v-if="list.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="form.page"
                layout="prev, pager, next"
                @current-change="search"
                :page-count="totalPage">
            </el-pagination>
        </div>
        <!-- 添加物-->
        <el-dialog :title="drawerName" 
        width="86%"
        :visible.sync="drawer">
            <AddReportTest @saveName="saveName" @back="refresh"/>
        </el-dialog>
    </div>
</template>
<script>
import AddReportTest from '@/components/AddReportTest';
import {checkRules} from '@/utils/util.js'
export default {
    components:{
        AddReportTest
    },
    data(){
        return{
            pageSize:50,
            drawerName:"添加",
            drawer:false,
            addRule:checkRules(16),
            list:[],
            totalPage:1,
            form:{
                keyword:"",
                page:1
            }
        }
    },
    created(){
        this.search()
    },
    methods:{
        edClick(id){
            this.$router.push({
                path:"/Test/test?id="+id,
                query:{
                    id:id,
                }
            })
        },
        deleteRow(index,list){
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:"deleteReportTest",
                        id:list[index].id
                    }
                    this.$ajax.post(this.API.api,params).then(()=>{
                        this.loading = false
                        this.refresh()
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
                }).catch(()=>{

                })
           // list.splice(index,1)
        },
        refresh(){
            this.form.page = 1
            this.drawer = false
            this.search()
        },
        search(){
            if(this.totalPage<this.form.page){
                return
            }
            let params = {
                method:"getReportTest",
                keyword:this.form.keyword,
                page:this.page
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                console.log(data)
                if(data){
                    this.totalPage = parseInt(data.totalPage)
                    this.list = data.list
                }
                
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        saveName(val){
            this.drawerName = val
        }
    }
}
</script>